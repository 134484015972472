<template>
  <div>
    <v-row justify="center" v-if="this.dialog">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Assign Pharmacist</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-select
                    :items="users"
                    item-text="username"
                    item-value="id"
                    label="Select Pharmacist.."
                    v-model="formData.partner_user_id"
                    outlined>
                  </v-select>
                </v-col>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">Close</v-btn>
            <v-btn color="primary" @click="submit" :loading="submitting" :disabled="submitting">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <SuccessDialog ref="successDialog" message="Pharmacist assigned successfully"></SuccessDialog>
    <ErrorDialog ref="errorDialog" :message="errorMessage"></ErrorDialog>
  </div>
</template>

<script>
import SuccessDialog from "../../components/base/SuccessDialog";
import ErrorDialog from "../../components/base/ErrorDialog";

export default {
  name: "FollowUpForm",
  components: {ErrorDialog, SuccessDialog},
  created() {
    this.getUsersList();
  },
  data() {
    return {
      submitting: false,
      prescription_id: null,
      dialog: false,
      formData: {
        partner_user_id: null
      },
      users: [],
      errorMessage: ''
    }
  },
  methods: {
    showAssignForm(id) {
      this.dialog = true;
      this.prescription_id = id;
    },
    getUsersList() {
      this.$authApi.get('users').then(res => {
        console.log(res.data)
        this.users = res.data.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    submit() {
      this.submitting = true;
      this.$authApi.post(`prescriptions/${this.prescription_id}/assign`, this.formData).then(res => {
        this.submitting = false;
        this.dialog = false;
        this.$refs.successDialog.openDialog();
        this.$emit('submitted');
      }).catch(error => {
        this.submitting = false;
        this.dialog = false;
        this.$refs.errorDialog.openDialog();
        this.errorMessage = 'Something went wrong';
      })
    }
  }
}
</script>

<style scoped>
.hidden {
  display: none !important;
}
</style>
